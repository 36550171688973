

























































































  import {Component, Prop} from 'vue-property-decorator';
  import LoadingHandler from '@/utils/loading-handler';

  import {ValidateWithScroll} from "@/utils/validation-handler";

  import CompanyEntity, {ValidatorRules} from "@/entities/company-entity";
  import {
    CompanyRepository,
    LabelSettingUpdateRequest,
  } from "@/repositories/company-repository";
  import AuthViewBase from "@/views/AuthViewBase";
  import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
  import { NutritionDecimalPoint } from '@/entities/company-setting-entity';
  import SettingCommonSection from "@/views/common/mypage/company/components/SettingCommonSection.vue";
  import {help as PopoverText} from '@/lang/help/setting';
  import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
  import MyPageBodySection from "@/views/common/mypage/components/MyPageBodySection.vue";
  import MyPageBodySettingRow from "@/views/common/mypage/components/MyPageBodySettingRow.vue";

  @Component({
    components: {
      MyPageBodySettingRow,
      MyPageBodySection,
      MypageBodyHeader,
      SettingCommonSection,
      CanEditButton
    }
  })
  export default class extends AuthViewBase {
    private company:CompanyEntity = null as any;

    private readonly NutritionDecimalPoint = NutritionDecimalPoint;
    private readonly PopoverText = PopoverText;

    private updateRequest:LabelSettingUpdateRequest = null as any;

    private initialized: boolean = false;

    private async created() {
      this.company = await (new CompanyRepository()).findById(this.companyId);

      const s = this.company.setting;
      this.updateRequest = {
        isAutoCreateIngredientFromSpec: s.isAutoCreateIngredientFromSpec,
        useIntraCategory: s.useIntraCategory,
        isENumberVisible: s.isENumberVisible,

        isDepartmentEnabled: s.isDepartmentEnabled,
        isAccessLogVisible: s.isAccessLogVisible,
        accessibilityToOtherDeptResources: s.accessibilityToOtherDeptResources,
        departments: this.company.departments.slice(),

        decimalPlaceCalorie: s.decimalPlaceCalorie,
        decimalPlaceNonCalorie: s.decimalPlaceNonCalorie,
        displayDeprecatedNonGmoAsNonGmo: s.displayDeprecatedNonGmoAsNonGmo,
        hiddenWhenAllSameNameIngredientInvisible: s.hiddenWhenAllSameNameIngredientInvisible,

        passwordMinCharLength: s.passwordMinCharLength,
        passwordMinTypeLength: s.passwordMinTypeLength,
        isMfaEmailEnabled: s.isMfaEmailEnabled,
        accessibleIpAddresses: s.accessibleIpAddresses,

        enableBrotherPrint: s.enableBrotherPrint,
      };

      this.initialized = true;
    }

    private async submit() {
      if (!(await ValidateWithScroll(this.$refs.form as any))) return;

      LoadingHandler(() => {
        return (new CompanyRepository).updateSettingLabel(this.companyId, this.updateRequest);
      }).then(() => {
        this.$message({type: 'success', message: this.$t('規格書設定を更新しました。画面をリロードします') });
        setTimeout(() => location.reload(), 1500);
      })
    }
}
